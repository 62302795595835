import { removeExtraSymbolsFromBase64, toBase64 } from 'helpers';
import TemplateSignerBase from './template-signer-base';
import { Certificate, TemplateData } from 'client/models';

const TEST_XML_SIGN = `<?xml version="1.0" encoding="utf-8" standalone="no"?>
<?xml-stylesheet type="text/xsl" href="#stylesheet"?>
<!DOCTYPE ON_DOVBB_1_928_00_01_02 [
    <!ELEMENT xsl:stylesheet (#PCDATA)>
    <!ATTLIST xsl:stylesheet id ID #REQUIRED>
]>
<soapenv:Envelope xmlns:ds="http://www.w3.org/2000/09/xmldsig#"
    xmlns:fil="http://ru/ibs/fss/ln/ws/FileOperationsLn.wsdl"
    xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
    xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"
    xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">
    <soapenv:Header>
    </soapenv:Header>
    <soapenv:Body>
        <Файл />
        <Visualization wsu:Id="visualization">
            <xsl:stylesheet xmlns:ds="http://www.w3.org/2000/09/xmldsig#"
                xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
                xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"
                xmlns:xsl="http://www.w3.org/1999/XSL/Transform" id="stylesheet" version="1.0">
                <xsl:output method="html" />
                <xsl:strip-space elements="div h3 h2 p" />

                <xsl:template match="soapenv:Envelope">
                    <html lang="ru">
                        <head>
                            <meta charset="UTF-8" />
                            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                            <meta content="IE=edge" http-equiv="X-UA-Compatible" />
                        </head>
                        <body>
                            <div
                                style="padding: 100px; border: 10px solid black; text-align: center;">
                                <h2>Тестовая подпись</h2>
                            </div>

                        </body>
                    </html>
                </xsl:template>
            </xsl:stylesheet>
        </Visualization>
    </soapenv:Body>
</soapenv:Envelope>`;

const MOCK_CERTIFICATES = [
  {
    FriendlyName: 'Венера',
    Thumbprint: '8488C0B1D1D4F55442D3D6F7674ED461BCD2B800',
    Base64RawData:
      'MIIEATCCA6igAwIBAgIRAUEN6tGDqkytgMPPU5GYat4wCgYIKoZIzj0EAwIwggFOMRUwEwYFKoUDZAQTCjc3NDM3MDcxNjAxFjAUBgUqhQNkAxMLNzc0MzcwNzE2MDExGDAWBgUqhQNkARMNNTA4Nzc0NjE0NTM4MzELMAkGA1UEBhMCUlUxGDAWBgNVBAgMDzc3INCc0L7RgdC60LLQsDEZMBcGA1UEBwwQ0JMuINCc0J7QodCa0JLQkDE9MDsGA1UECQw00LMg0JzQvtGB0LrQstCwLCDRg9C7INCd0LDQvNGR0YLQutC40L3QsCwg0LQgMTYg0LogMTEVMBMGA1UECgwM0JLQtdC90LXRgNCwMRUwEwYDVQQDDAzQktC10L3QtdGA0LAxJjAkBgNVBAwMHdCU0LjRgNC10LrRgtC+0YAg0JLQtdC90LXRgNGLMRUwEwYDVQQqDAzQktC10L3QtdGA0LAxFTATBgNVBAQMDNCS0LXQvdC10YDQsDAeFw0yNDEyMjcwNjE5MzdaFw0yNTAxMjcwNjE5MzdaMIIBTjEVMBMGBSqFA2QEEwo3NzQzNzA3MTYwMRYwFAYFKoUDZAMTCzc3NDM3MDcxNjAxMRgwFgYFKoUDZAETDTUwODc3NDYxNDUzODMxCzAJBgNVBAYTAlJVMRgwFgYDVQQIDA83NyDQnNC+0YHQutCy0LAxGTAXBgNVBAcMENCTLiDQnNCe0KHQmtCS0JAxPTA7BgNVBAkMNNCzINCc0L7RgdC60LLQsCwg0YPQuyDQndCw0LzRkdGC0LrQuNC90LAsINC0IDE2INC6IDExFTATBgNVBAoMDNCS0LXQvdC10YDQsDEVMBMGA1UEAwwM0JLQtdC90LXRgNCwMSYwJAYDVQQMDB3QlNC40YDQtdC60YLQvtGAINCS0LXQvdC10YDRizEVMBMGA1UEKgwM0JLQtdC90LXRgNCwMRUwEwYDVQQEDAzQktC10L3QtdGA0LAwWTATBgcqhkjOPQIBBggqhkjOPQMBBwNCAAS3szlZ7YpuArFrFWbxhTpO3W9nk4nh885Be8iit8cWjRiqqZNrCQx7ni+LufU16NprEuKzpwjHGmcXv8pZ3E1Wo2MwYTASBgNVHRMBAf8ECDAGAQH/AgEBMBwGA1UdJQEB/wQSMBAGBioDBAUGBwYGUwQFBgcIMA4GA1UdDwEB/wQEAwIBBjAdBgNVHQ4EFgQUKkqFBl+/EGQF0v6wUJPSyswWYu0wCgYIKoZIzj0EAwIDRwAwRAIgWnZWBT/haCme0dkef8+xhbZr2zZ6FmLYS35ckw5YT0kCIGjI3AJFKovalFHXfHvKRPqAZ7M13At8DvDw3b796Q7m',
    Subject: {
      ИНН: '',
      Владелец: 'Венера',
      Город: '-',
      Регион: '77437071601',
      Компания: 'Венера',
      ОГРН: '5087746145383',
      ИННЮЛ: '7743707160',
      СНИЛС: '77437071601',
      Улица: '',
      Фамилия: 'Венера',
      'Имя/Отчество': 'Венера',
      Должность: '-',
    },
  },
  {
    FriendlyName: 'Марс',
    Thumbprint: '9C158A54230D3A18E11AA2AAF0BC83E787BD62C3',
    Base64RawData:
      'MIIFlTCCBUKgAwIBAgIQAdpO1cFf6eAAAKHUAAUAATAKBggqhQMHAQEDAjCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoTAeFw0yNDAxMjQxNDU4MDBaFw0yNDEyMDkyMDU5NTlaMIIBIDEdMBsGCSqGSIb3DQEJARYObWFyc0B0ZXN0LnRlc3QxCjAIBgNVBAkMAS0xCzAJBgNVBAYTAlJVMQowCAYDVQQIDAEtMQowCAYDVQQHDAEtMSgwJgYDVQQqDB/QktC40YLQsNC70LjQuSDQn9Cw0LLQu9C+0LLQuNGHMR0wGwYDVQQEDBTQndC40LrQvtC70LDQtdCy0LjRhzEKMAgGA1UEDAwBLTEKMAgGA1UECwwBLTERMA8GA1UECgwI0JzQsNGA0YExFjAUBgUqhQNkAxILNTA0NTAxNjU2MDExGDAWBgUqhQNkARINMTAyNTAwNTkxNzI5ODEVMBMGBSqFA2QEEgo1MDQ1MDE2NTYwMREwDwYDVQQDDAjQnNCw0YDRgTBeMBcGCCqFAwcBAQEBMAsGCSqFAwcBAgEBAQNDAARAbwZ9IJRLmR9M/blI9x9eXj7cZKi87lHsd7MbbSYI7gNTnl2YG59GlVTuQ3iTp+I8qY9ba2z2rVQRtv3e1cPtY4EJADAwMDUwMDAxo4ICfzCCAnswDgYDVR0PAQH/BAQDAgP4MB0GA1UdJQQWMBQGCCsGAQUFBwMCBggrBgEFBQcDBDAMBgUqhQNkcgQDAgEAMB0GA1UdDgQWBBQybIBCkTQGxo6LtYYKuEy+3ugQ0jAdBgNVHSAEFjAUMAgGBiqFA2RxATAIBgYqhQNkcQIwHAYFKoUDZG8EEwwRVmlQTmV0IFBLSSBDbGllbnQwgbMGBSqFA2RwBIGpMIGmDA5WaVBOZXQgQ1NQIDQuNAwm0KLQtdGB0YLQvtCy0YvQuSDQo9CmINCY0L3RhNC+0KLQtdCa0KEMNtCX0LDQutC70Y7Rh9C10L3QuNC1IOKEljE0OS8zLzIvMS0yMDU5INC+0YIgMTcuMDguMjAyMgw00JfQsNC60LvRjtGH0LXQvdC40LUg4oSWIDE0OS83LzYtMjUyINC+0YIgMDQuMDcuMjAyMzCCASgGA1UdIwSCAR8wggEbgBSXWay7IoBvqKnd5wg9XYFqVu7iTKGB8KSB7TCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoYIQAdoieI68g7AAAJbVAAUAATAKBggqhQMHAQEDAgNBAPzZxnBHNgr9ZotoIWUqSmQZjbc59zKVFjZNsRd8ciA+z3ioIwFhnrIkX6/NxxZSsPG1Au2ajFR48BssU3l5v4w=',
    Subject: {
      ИНН: '',
      Владелец: 'Марс',
      Город: '-',
      Регион: '50450165601',
      Компания: 'Марс',
      ОГРН: '1025005917298',
      ИННЮЛ: '5045016560',
      СНИЛС: '50450165601',
      Улица: '',
      Фамилия: 'Николаевич',
      'Имя/Отчество': 'Виталий Павлович',
      Должность: '-',
    },
  },
  {
    FriendlyName: 'Сатурн',
    Thumbprint: '440292D0B7D0A572A95044D753A0BDC617F9BAB0',
    Base64RawData:
      'MIIFejCCBSegAwIBAgIQAdpSU7ccT2AAAKJjAAUAATAKBggqhQMHAQEDAjCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoTAeFw0yNDAxMjkwMTM3MDBaFw0yNDEyMDkyMDU5NTlaMIIBDzEfMB0GCSqGSIb3DQEJARYQc2F0dXJuQHRlc3QudGVzdDEKMAgGA1UECQwBLTELMAkGA1UEBhMCUlUxCjAIBgNVBAgMAS0xCjAIBgNVBAcMAS0xFTATBgNVBCoMDNCh0LDRgtGD0YDQvTEVMBMGA1UEBAwM0KHQsNGC0YPRgNC9MQowCAYDVQQMDAEtMQowCAYDVQQLDAEtMRUwEwYDVQQKDAzQodCw0YLRg9GA0L0xFjAUBgUqhQNkAxILMjMxMTAwNjk2MTExGDAWBgUqhQNkARINMTAyMjMwMTgxMjI0NjEVMBMGBSqFA2QEEgoyMzExMDA2OTYxMRUwEwYDVQQDDAzQodCw0YLRg9GA0L0wXjAXBggqhQMHAQEBATALBgkqhQMHAQIBAQEDQwAEQDNe4dJX/zlzpv1bcDwHQjUrXyG8YlYocq8zxQKv1yuaZzu1xpmd33WXBx5MKsDMRswOsztyC8axUmpmai9e9JKBCQAwMDA1MDAwMaOCAnUwggJxMA4GA1UdDwEB/wQEAwID+DAdBgNVHSUEFjAUBggrBgEFBQcDAgYIKwYBBQUHAwQwDAYFKoUDZHIEAwIBADAdBgNVHQ4EFgQUL82LFzGPNK1GiFKJsvRrQx7NzWYwEwYDVR0gBAwwCjAIBgYqhQNkcQIwHAYFKoUDZG8EEwwRVmlQTmV0IFBLSSBDbGllbnQwgbMGBSqFA2RwBIGpMIGmDA5WaVBOZXQgQ1NQIDQuNAwm0KLQtdGB0YLQvtCy0YvQuSDQo9CmINCY0L3RhNC+0KLQtdCa0KEMNtCX0LDQutC70Y7Rh9C10L3QuNC1IOKEljE0OS8zLzIvMS0yMDU5INC+0YIgMTcuMDguMjAyMgw00JfQsNC60LvRjtGH0LXQvdC40LUg4oSWIDE0OS83LzYtMjUyINC+0YIgMDQuMDcuMjAyMzCCASgGA1UdIwSCAR8wggEbgBSXWay7IoBvqKnd5wg9XYFqVu7iTKGB8KSB7TCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoYIQAdoieI68g7AAAJbVAAUAATAKBggqhQMHAQEDAgNBAMvi4cUKAjbC9mUAVP0w4FCVJ0LaE8IcZNKjuBbmgxK50EZm3fUlSMqRy0WbW9+VSAYtDKwm+ajpyP5iDHaTD7I=',
    Subject: {
      ИНН: '',
      Владелец: 'Сатурн',
      Город: '-',
      Регион: '23110069611',
      Компания: 'Сатурн',
      ОГРН: '1022301812246',
      ИННЮЛ: '2311006961',
      СНИЛС: '23110069611',
      Улица: '',
      Фамилия: 'Сатурн',
      'Имя/Отчество': 'Сатурн',
      Должность: '-',
    },
  },
  {
    FriendlyName: 'Плутон',
    Thumbprint: '32DEFF44518C280B1352AE02B27BE957B682B8FF',
    Base64RawData:
      'MIIFfjCCBSugAwIBAgIQAdpSU5jDwWAAAKJhAAUAATAKBggqhQMHAQEDAjCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoTAeFw0yNDAxMjkwMTM2MDBaFw0yNDEyMDkyMDU5NTlaMIIBEzEjMCEGCSqGSIb3DQEJARYUcGx1dG9uQHBsdXRvbi5wbHV0b24xCjAIBgNVBAkMAS0xCzAJBgNVBAYTAlJVMQowCAYDVQQIDAEtMQowCAYDVQQHDAEtMRUwEwYDVQQqDAzQn9C70YPRgtC+0L0xFTATBgNVBAQMDNCf0LvRg9GC0L7QvTEKMAgGA1UEDAwBLTEKMAgGA1UECwwBLTEVMBMGA1UECgwM0J/Qu9GD0YLQvtC9MRYwFAYFKoUDZAMSCzc3MDkwOTMyNTUxMRgwFgYFKoUDZAESDTEwMjc3MzkwNTczNTYxFTATBgUqhQNkBBIKNzcwOTA5MzI1NTEVMBMGA1UEAwwM0J/Qu9GD0YLQvtC9MF4wFwYIKoUDBwEBAQEwCwYJKoUDBwECAQEBA0MABEA70qpgryc1WKo/v2V2vlliQCpYfJbTg6WDVzoWH3sHOXDSzUaCFlEm7JUt8nnjfBJ1psf6AOIkNLfNRj34DM9fgQkAMDAwNTAwMDGjggJ1MIICcTAOBgNVHQ8BAf8EBAMCA/gwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMAwGBSqFA2RyBAMCAQAwHQYDVR0OBBYEFBiYuhUn3KHxltTeJYiJxR+SIcG4MBMGA1UdIAQMMAowCAYGKoUDZHECMBwGBSqFA2RvBBMMEVZpUE5ldCBQS0kgQ2xpZW50MIGzBgUqhQNkcASBqTCBpgwOVmlQTmV0IENTUCA0LjQMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChDDbQl9Cw0LrQu9GO0YfQtdC90LjQtSDihJYxNDkvMy8yLzEtMjA1OSDQvtGCIDE3LjA4LjIwMjIMNNCX0LDQutC70Y7Rh9C10L3QuNC1IOKEliAxNDkvNy82LTI1MiDQvtGCIDA0LjA3LjIwMjMwggEoBgNVHSMEggEfMIIBG4AUl1msuyKAb6ip3ecIPV2Balbu4kyhgfCkge0wgeoxLDAqBgNVBAkMI9GD0Lsu0J7RgtGA0LDQtNC90LDRjywgMtCRINGB0YLRgC4xMQswCQYDVQQGEwJSVTEZMBcGA1UEBwwQ0LMuINCc0L7RgdC60LLQsDEwMC4GA1UECwwn0KPQtNC+0YHRgtC+0LLQtdGA0Y/RjtGJ0LjQuSDRhtC10L3RgtGAMS8wLQYDVQQKDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoTEvMC0GA1UEAwwm0KLQtdGB0YLQvtCy0YvQuSDQo9CmINCY0L3RhNC+0KLQtdCa0KGCEAHaIniOvIOwAACW1QAFAAEwCgYIKoUDBwEBAwIDQQCzDWFkzh0X1KnF/9WrJGvUYY5sPoshUf7MOVz4fww4DGojAA8/eVGltnqr7Y/6zWv7ycAVbFkfKBVS922DYi/l',
    Subject: {
      ИНН: '',
      Владелец: 'Плутон',
      Город: '-',
      Регион: '77090932551',
      Компания: 'Плутон',
      ОГРН: '1027739057356',
      ИННЮЛ: '7709093255',
      СНИЛС: '77090932551',
      Улица: '',
      Фамилия: 'Плутон',
      'Имя/Отчество': 'Плутон',
      Должность: '-',
    },
  },
  {
    FriendlyName: 'John Doe',
    Thumbprint: 'F8DDF8CEC6811AA59EE872569984593502FDD3A2',
    Base64RawData:
      'MIIFIDCCBM2gAwIBAgIQAdpo5q+4n5AAAKeXAAUAATAKBggqhQMHAQEDAjCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoTAeFw0yNDAyMjYxOTA0MDBaFw0yNDEyMDkyMDU5NTlaMIG2MR8wHQYJKoZIhvcNAQkBFhBnZ3JlZ0Blcmdlci5ncmVnMQowCAYDVQQJDAEtMQswCQYDVQQGEwJSVTEKMAgGA1UECAwBLTEKMAgGA1UEBwwBLTEMMAoGA1UEKgwDRG9lMQ0wCwYDVQQEDARKb2huMRYwFAYFKoUDZAMSCzQzNTI1MjQzNTI0MRowGAYIKoUDA4EDAQESDDUzMjU0MzUyMzQ1MzERMA8GA1UEAwwISm9obiBEb2UwXjAXBggqhQMHAQEBATALBgkqhQMHAQIBAQEDQwAEQKjFL/h2iFbeQaGVGF/79BoErXpZURdBN6vJOkGA/SGbHIy8A40/sowTG9GSVEGKYzyGtphR1I3g0/FgMIBhHQWBCQAwMDA1MDAwMaOCAnUwggJxMA4GA1UdDwEB/wQEAwID+DAdBgNVHSUEFjAUBggrBgEFBQcDAgYIKwYBBQUHAwQwDAYFKoUDZHIEAwIBADAdBgNVHQ4EFgQUA2OghZ0f/mM3BMdg4cd5DNxn1RgwEwYDVR0gBAwwCjAIBgYqhQNkcQIwHAYFKoUDZG8EEwwRVmlQTmV0IFBLSSBDbGllbnQwgbMGBSqFA2RwBIGpMIGmDA5WaVBOZXQgQ1NQIDQuNAwm0KLQtdGB0YLQvtCy0YvQuSDQo9CmINCY0L3RhNC+0KLQtdCa0KEMNtCX0LDQutC70Y7Rh9C10L3QuNC1IOKEljE0OS8zLzIvMS0yMDU5INC+0YIgMTcuMDguMjAyMgw00JfQsNC60LvRjtGH0LXQvdC40LUg4oSWIDE0OS83LzYtMjUyINC+0YIgMDQuMDcuMjAyMzCCASgGA1UdIwSCAR8wggEbgBSXWay7IoBvqKnd5wg9XYFqVu7iTKGB8KSB7TCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoYIQAdoieI68g7AAAJbVAAUAATAKBggqhQMHAQEDAgNBAPCE12Ua1aoZsIGtd+GFWeUwrBcvlx5dP20GPfFWe1NTTyS4nsMUrmwIXrCiOePvigKmA+e6jagvlNKxRn6cVbg=',
    Subject: {
      ОГРН: '',
      ИННЮЛ: '',
      Улица: '',
      Город: '-',
      Компания: '',
      Должность: '',
      Фамилия: 'John',
      ИНН: '532543523453',
      Владелец: 'John Doe',
      СНИЛС: '43525243524',
      Регион: '43525243524',
      'Имя/Отчество': 'Doe',
    },
  },
  {
    FriendlyName: 'Smith John Jonson',
    Thumbprint: '6D0F1B3480BF7B07FFD2C4BA9615030B7C5DC6A5',
    Base64RawData:
      'MIIFMTCCBN6gAwIBAgIQAdp3PE17S8AAAKsoAAUAATAKBggqhQMHAQEDAjCB6jEsMCoGA1UECQwj0YPQuy7QntGC0YDQsNC00L3QsNGPLCAy0JEg0YHRgtGALjExCzAJBgNVBAYTAlJVMRkwFwYDVQQHDBDQsy4g0JzQvtGB0LrQstCwMTAwLgYDVQQLDCfQo9C00L7RgdGC0L7QstC10YDRj9GO0YnQuNC5INGG0LXQvdGC0YAxLzAtBgNVBAoMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChMS8wLQYDVQQDDCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoTAeFw0yNDAzMTYwMDUzMDBaFw0yNDEyMDkyMDU5NTlaMIHHMR4wHAYJKoZIhvcNAQkBFg92YmJiYkBiYmJiYi5iYmIxCjAIBgNVBAkMAS0xCzAJBgNVBAYTAlJVMQowCAYDVQQIDAEtMQowCAYDVQQHDAEtMRQwEgYDVQQqDAtKb2huIEpvbnNvbjEOMAwGA1UEBAwFU21pdGgxFjAUBgUqhQNkAxILNzc3NzU1NTU1NTcxGjAYBggqhQMDgQMBARIMNzc3NzU1NTU1NTc3MRowGAYDVQQDDBFTbWl0aCBKb2huIEpvbnNvbjBeMBcGCCqFAwcBAQEBMAsGCSqFAwcBAgEBAQNDAARA4OUy/2WIQ7VRN+gwdQitf9HjngU0vja7GPJVUuMiFjlJziw41cstlP/R6PDv+HD0GwYt1m5qeL+/TFVRWe+ERoEJADAwMDUwMDAxo4ICdTCCAnEwDgYDVR0PAQH/BAQDAgP4MB0GA1UdJQQWMBQGCCsGAQUFBwMCBggrBgEFBQcDBDAMBgUqhQNkcgQDAgEAMB0GA1UdDgQWBBR0klBrU/CNsvZ2hdfavtFsXzi5TjATBgNVHSAEDDAKMAgGBiqFA2RxAjAcBgUqhQNkbwQTDBFWaVBOZXQgUEtJIENsaWVudDCBswYFKoUDZHAEgakwgaYMDlZpUE5ldCBDU1AgNC40DCbQotC10YHRgtC+0LLRi9C5INCj0KYg0JjQvdGE0L7QotC10JrQoQw20JfQsNC60LvRjtGH0LXQvdC40LUg4oSWMTQ5LzMvMi8xLTIwNTkg0L7RgiAxNy4wOC4yMDIyDDTQl9Cw0LrQu9GO0YfQtdC90LjQtSDihJYgMTQ5LzcvNi0yNTIg0L7RgiAwNC4wNy4yMDIzMIIBKAYDVR0jBIIBHzCCARuAFJdZrLsigG+oqd3nCD1dgWpW7uJMoYHwpIHtMIHqMSwwKgYDVQQJDCPRg9C7LtCe0YLRgNCw0LTQvdCw0Y8sIDLQkSDRgdGC0YAuMTELMAkGA1UEBhMCUlUxGTAXBgNVBAcMENCzLiDQnNC+0YHQutCy0LAxMDAuBgNVBAsMJ9Cj0LTQvtGB0YLQvtCy0LXRgNGP0Y7RidC40Lkg0YbQtdC90YLRgDEvMC0GA1UECgwm0KLQtdGB0YLQvtCy0YvQuSDQo9CmINCY0L3RhNC+0KLQtdCa0KExLzAtBgNVBAMMJtCi0LXRgdGC0L7QstGL0Lkg0KPQpiDQmNC90YTQvtCi0LXQmtChghAB2iJ4jryDsAAAltUABQABMAoGCCqFAwcBAQMCA0EATvgFZUf0IU1jltsMLjog8ZX3abxp0wrWm37xjk6jLE0dp+EF+plpHSdnTDgxYdsSFEVWffQfAdsGezAFxOawxQ==',
    Subject: {
      ОГРН: '',
      ИННЮЛ: '',
      Улица: '',
      Город: '-',
      Компания: '',
      Должность: '',
      Фамилия: 'Smith',
      ИНН: '777755555577',
      СНИЛС: '77775555557',
      Регион: '77775555557',
      Владелец: 'Smith John Jonson',
      'Имя/Отчество': 'John Jonson',
    },
  },
];

export default class MockSigner extends TemplateSignerBase {
  constructor() {
    super();
  }

  async getCertificates(): Promise<Certificate[]> {
    return MOCK_CERTIFICATES;
  }

  async sign({
    dataForSign,
    certificate,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<string> {
    const xmlToSign = TEST_XML_SIGN ?? dataForSign;

    return removeExtraSymbolsFromBase64(toBase64(xmlToSign));
  }

  async signXml({
    props,
    dataForSign,
    certificate,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<{ signature: string; detachedSignature: string }> {
    const xmlToSign = TEST_XML_SIGN ?? dataForSign;

    return {
      signature: removeExtraSymbolsFromBase64(toBase64(xmlToSign)),
      detachedSignature: '',
    };
  }
}
